import React from 'react';
import NavSec from '../home/NavSec';
import PageInfo from './PageInfo';
import Reveal from 'react-reveal/Reveal';
import Footer from '../footer/Footer';
import FAQsMain from './FAQsMain';

const FAQs = () => {
    return (
        <>
            <Reveal effect="animNav" duration="750">
                <NavSec />
            </Reveal>
            <Reveal effect="fadeInUp" duration="1250">
                <PageInfo />
            </Reveal><Reveal effect="portfolioAnim" duration="1400">
            <FAQsMain /> </Reveal>
            
            <Footer />
        </>
    );
};

export default FAQs;