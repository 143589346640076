import React, { useState, useEffect } from 'react';
import db from '../firebase/config';

const Main = () => {

    const [aboutInfos, setAboutInfos] = useState([]);

    useEffect(() => {
        db.collection("about").onSnapshot((snapshot) =>
            setAboutInfos(snapshot.docs.map((doc) => doc.data()))
        );
    }, [])

    return (
        <div className="aboutmeMain">
            {aboutInfos.map((about) => (
                <>
                    <img 
                        src={about.myImage} 
                        alt="my Image" 
                        className="myImage"     />
                    <span>
                        
                        <p className="aboutmeText" >
                            
                    <div>
                                <b className="ques special">{about.boldText}</b> {/* bold text */}
                            </div>
                            {about.text1}
                            <div>
                                <b className="ques special">{about.text2}</b> {/* bold text */}
                            </div>
                            {about.text3}
                            
                        </p>
                    <div class="techstack">
                    <h3>
                        <b className="ques">{about.text4}</b>
                        </h3>
                        <p class="techstack_descr">
                        {about.text5} 
                            </p>
                    <div class="techstack_main">
                            <div class="techstack_holder">
                                <img src={about.hc} alt="HTML, CSS"/>
                                <p  className="pl">
                                    HTML, CSS</p>
                            </div>
                            <div class="techstack_holder">
                                <img src={about.js} alt="JavaScript"
                                    
                                />
                                <p  className="pl">JavaScript</p>
                            </div>
                            <div class="techstack_holder">
                                    <img src={about.sass}  alt="Sass"
                                        
                                    />
                                    <p className="pl" >Sass</p>
                            </div>
                            <div class="techstack_holder">
                                            <img src={about.bootstrap}  alt="Bootstrap" />
                                            <p className="pl" >Bootstrap</p>
                            </div>
                            <div class="techstack_holder">
                                    <img src={about.reactjs}  alt="ReactJS"                        />
                                    <p className="pl" >ReactJS</p>
                            </div>
                        </div>
                        </div>
                        
                    </span>
                </>
            ))}
        </div>
    );
};

export default Main;