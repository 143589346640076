import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyCSFBBKGeUEYpCexs_PoZGBRYvC8P7ix4w",
    authDomain: "phoenix-a-v2.firebaseapp.com",
    projectId: "phoenix-a-v2",
    storageBucket: "phoenix-a-v2.appspot.com",
    messagingSenderId: "517092875858",
    appId: "1:517092875858:web:bad14138dfea9b5b97098b",
    measurementId: "G-LKET5BFMYG"
  };
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

export default db;