import React from 'react';

const PageInfo = () => {
    return (
        <>
            <div className="titles">
                <h3 className="title1">
                    About me
                </h3>
                <p className="title2">Personal page</p>
            </div>
        </>
    );
};

export default PageInfo;