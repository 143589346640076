import React, { useState, useEffect } from 'react';
import db from '../firebase/config';
import {
    Container, Row, Col
} from 'reactstrap';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const FAQsMain = () => {

    const [setups, setSetups] = useState([]);

    useEffect(() => {
        db.collection("faqs").onSnapshot((snapshot) =>
            setSetups(snapshot.docs.map((doc) => doc.data()))
        );
    }, [])

    return (
        <div className="setupMain">
            <Container>
                <Row>
                    <Col md=" 8">
                        <div className="faqs">
                            <div className="faqsMenu">
                                <h3>
                                    What is your name?
                                </h3>
                                <p>
                                    I am Asadbek Nematov.
                                </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    Where are you from?
                                </h3>
                                <p>
I live in Yangiyul, which is in the Tashkent province, Uzbekistan.     
                                </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    How old are you?
                                </h3>
                                <p>
I was born on September 29, 2003. Now I am 19 years old.
                                </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    Where do you study?
                                </h3>
                                <p>
                                    
I graduated from high school in 2021 and took a gap year to enter MIT in the USA.</p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    How well do you know English?
                                </h3>
                                <p>
                                My English level is C1, and I can speak at length without noticeable effort or loss of coherence. </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                Where did you learn Frontend?
                                </h3>
                                <p>
                                    
I learned and still keep learning Frontend mostly from YouTube and other sources. I also follow some developers from Uzbekistan and around the world on social media. </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    Why did you choose the IT sphere?                                </h3>
                                <p>
The reason is I do enjoy coding a lot. Moreover, I believe that if people love what they do, they will succeed in their profession.  </p>
                            </div>
                            <div className="faqsMenu">
                                <h3>
                                    How can we contact you?                                </h3> 
                                    <p> 
You can contact me via any social media that I left on the home page. Do not hesitate to reach me. </p>
                                </div>
                        </div>
                        
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default FAQsMain;