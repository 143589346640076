import React from 'react';
import NavSec from '../home/NavSec';
import PageInfo from './PageInfo';
import Reveal from 'react-reveal/Reveal';
import Project from './Project';
import Footer from '../footer/Footer';


function Portfolio() {
    return (
        <>
            <Reveal effect="animNav" duration="750">
                <NavSec />
            </Reveal>
            <Reveal effect="fadeInUp" duration="1250">
                <PageInfo />
            </Reveal>
            <Reveal effect="portfolioAnim" duration="1260">
                <Project />
            </Reveal>
            <Footer />
        </>
    );
}

export default Portfolio;